// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body{
    margin: 0;
    padding: 0;
}

main{
    display: flex;
    flex-direction: row;

    width: 100%;
}

#toolbar{
    height: 100vh;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 89%, rgba(229,229,229,1) 100%);
}

#content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;

}

div#single-spa-application\\:\\@freston\\/cc-caller{
    
    flex-grow: 1;
    height: 100%;
    display: flex;
}

div#single-spa-application\\:\\@freston\\/cc-contacts{
    flex-grow: 1;
    height: 100%;
    display: flex;
}

.calls div#single-spa-application\\:\\@freston\\/cc-contacts{
    width: 30%;
    max-width: 350px;
}`, "",{"version":3,"sources":["webpack://./src/styles/general.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,4BAA4B;IAC5B,qFAAqF;AACzF;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB","sourcesContent":["html, body{\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\nmain{\r\n    display: flex;\r\n    flex-direction: row;\r\n\r\n    width: 100%;\r\n}\r\n\r\n#toolbar{\r\n    height: 100vh;\r\n    background: rgb(255,255,255);\r\n    background: linear-gradient(90deg, rgba(255,255,255,1) 89%, rgba(229,229,229,1) 100%);\r\n}\r\n\r\n#content{\r\n    flex-grow: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n}\r\n\r\ndiv#single-spa-application\\:\\@freston\\/cc-caller{\r\n    \r\n    flex-grow: 1;\r\n    height: 100%;\r\n    display: flex;\r\n}\r\n\r\ndiv#single-spa-application\\:\\@freston\\/cc-contacts{\r\n    flex-grow: 1;\r\n    height: 100%;\r\n    display: flex;\r\n}\r\n\r\n.calls div#single-spa-application\\:\\@freston\\/cc-contacts{\r\n    width: 30%;\r\n    max-width: 350px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
