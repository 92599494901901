import { createDarkTheme, createLightTheme } from '@fluentui/react-components';
import { registerApplication, start, navigateToUrl } from 'single-spa';
import {
	constructApplications,
	constructLayoutEngine,
	constructRoutes,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import keycloak from './services/keycloak';

import { v4 as uuidv4 } from 'uuid';
import './styles/general.css';

const apiAddress =
	window?.env?.API_ADDRESS ||
	process.env.API_ADDRESS ||
	'https://didntworked.com';

console.log('API Address:', apiAddress);

const contactCenter = {
	10: '#020205',
	20: '#141622',
	30: '#1F233B',
	40: '#282E51',
	50: '#313967',
	60: '#39457F',
	70: '#425298',
	80: '#4A5EB1',
	90: '#536BCB',
	100: '#5C78E5',
	110: '#6986FB',
	120: '#8195FC',
	30: '#97A4FD',
	40: '#ABB4FE',
	150: '#BEC3FF',
	160: '#D0D3FF',
};

const useTheme = () => {
	const lightTheme = {
		...createLightTheme(contactCenter),
	};

	const darkTheme = {
		...createDarkTheme(contactCenter),
	};

	darkTheme.colorBrandForeground1 = contactCenter[110];
	darkTheme.colorBrandForeground2 = contactCenter[120];

	return {
		lightTheme,
		darkTheme,
	};
};

function startSingleSpa() {
	const routes = constructRoutes(microfrontendLayout);
	const applications = constructApplications({
		routes,
		loadApp({ name }) {
			return System.import(name);
		},
	});

	const correlationKey = uuidv4();

	applications.forEach((app) => {
		app.customProps = { apiAddress, keycloak, correlationKey, navigation: navigateToUrl };
		registerApplication(app);
	});

	const layoutEngine = constructLayoutEngine({ routes, applications });
	layoutEngine.activate();

	start();
}

// Inicialize o Keycloak e depois o single-spa
keycloak
	.init({ onLoad: 'login-required' })
	.then((authenticated) => {
		if (authenticated) {
			console.log('Authenticated', keycloak);
			startSingleSpa(); // Se autenticado, inicie o single-spa
		} else {
			console.warn('Not authenticated');
			keycloak.login(); // Redireciona para o login do Keycloak
		}
	})
	.catch((err) => {
		console.error('Failed to initialize Keycloak', err);
	});
