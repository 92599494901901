import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    realm: 'ccs',
    url: 'https://dev-auth.freston.io/',
    clientId: 'agent-dashboard',
    //clientId: 'agent-dashboard-dev',
    
  })

  const keycloak2 = new Keycloak({
    realm: 'contact-center',
    url: 'http://localhost:8081/',
    clientId: 'ccs-agent-app',
    
  })


export default keycloak;